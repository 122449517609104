.inputfield {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.inputfield label {
  font-weight: bold;
  font-size: 1.6rem;
  padding-left: 8px;
}

.inputfield input {
  margin: 8px 0;
  height: 45px;
  font-size: 2.2rem;
  padding-left: 8px;
}
