.profile-form {
  display: flex;
  flex-direction: column;
}

.profile-form button[type="submit"] {
  margin-top: 20px;
  height: 50px;
  font-size: 1.6rem;
}
