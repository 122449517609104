.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.5rem;
  background-color: #fff;
  border-bottom: 1px solid rgb(206, 206, 206);
  font-size: 1.6rem;
}

.left {
  display: flex;
  align-items: center;
}

.left h1 {
  margin-left: 25px;
}

.logo {
  width: 7.5rem;
}

.links {
  margin-left: 50px;
}

.links a {
  color: #333;
  text-decoration: none;
}

.links a:not(:first-of-type) {
  margin-left: 25px;
}

.links a:hover {
  text-decoration: underline;
}

.links .is-active {
  text-decoration: underline;
  color: #4470f0;
  font-weight: bold;
}

.header-btns {
  display: flex;
  height: 100%;
}

.header-btns button {
  width: 200px;
  height: 100%;
  margin-right: 15px;
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
}
