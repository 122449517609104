.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.user-inner {
  width: 33%;
}

.user-inner h1 {
  margin-bottom: 25px;
}
